import React, { FC } from 'react';
import Image from 'next/image';

export type RemoteImageSource = {
  maxWidth: string;
  src: string;
  srcDpr2?: string;
};

export interface IRemoteImageProps {
  /*
   * - Sources will be converted to 2x and webp images.
   * - Sources must be sorted by display-size, started by the smallest.
   */
  sources: RemoteImageSource[];
  src: string; // Alternative image
  alt: string;
  // Width of the original image.
  width: number;
  // Height of the original image.
  height: number;
  blurSrc?: string;
  title?: string;
  priority?: boolean;
  className?: string;
  lazyloading?: boolean;
}

export const RemoteImage: FC<IRemoteImageProps> = ({
  sources, src, blurSrc, alt, width, height, title = alt, priority = false, className = '', lazyloading = false,
}) => {
  return (
    <>
      <picture className={className}>
        {sources.map((source, index) => {
          return (
            <React.Fragment key={source.maxWidth}>
              <source
                key={source.maxWidth}
                srcSet={`${source.src} 1x, ${source.srcDpr2 ?? source.src} 2x`}
                media={`(max-width: ${source.maxWidth})`}
                type={'image/webp'}
              />

              {(index + 1) === sources.length && (
                <source
                  srcSet={source.src}
                  media={`(min-width: ${source.maxWidth})`}
                  type={'image/webp'}
                />
              )}
            </React.Fragment>
          );
        })}

        <Image
          src={src}
          alt={alt}
          title={title}
          // placeholder={blurUrl ? 'blur' : undefined}
          // blurDataURL={blurUrl?.toString() ?? undefined}
          unoptimized={true}
          priority={priority}
          width={width}
          height={height}
          className={className}
          loading={true === lazyloading ? 'lazy' : undefined}
        />
      </picture>
    </>
  );

};
