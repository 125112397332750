import { Icon, spacingCalculator } from '@digando/react-component-library';
import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';

type RentalStationListDeliveryTextProps = {
  hasDelivery: boolean;
}

const RentalStationListDeliveryTextComponent: FC<RentalStationListDeliveryTextProps> = ({ hasDelivery }) => {
  const [t] = useTranslation(['common']);

  if (true === hasDelivery) {
    return (<Delivery>
      <Icon size={'30px'} icon={'zustellung'} /> {' ' + t('search:delivery-possible')}
    </Delivery>);
  }

  return (<Delivery>
    <Icon size={'30px'} icon={'close'} /> {' ' + t('search:delivery-not-possible')}</Delivery>
  );
};

const Delivery = styled.div`
  display: flex;
  align-items: center;

  .icon {
    margin-right: ${spacingCalculator(1)};
  }
`;

export const RentalStationListDeliveryText = React.memo(RentalStationListDeliveryTextComponent);

