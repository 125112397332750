import React, { FC, useState } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import { useMutation } from '@apollo/client';
import {
  MutationSubscribeArgs,
  SubscribeNewsletterDocument,
  SubscribeNewsletterMutation,
} from '../../@types/codegen/graphql';
import Bugsnag from '@bugsnag/js';
import { newsletterSubscriptionTracking } from '../../lib/google/tracking-events';
import Link, { LinkProps } from 'next/link';
import { Popconfirm } from '../elements/Popconfirm/Popconfirm';
import { Button, Input, useForm, ValidationTypes } from '@digando/common-ui';
import styles from './newsletter.module.scss';

interface INewsletterComponentProps {
  identifier: string;
}

interface NewsletterForm extends HTMLFormElement {
  email: HTMLInputElement;
}

const NewsletterComponent: FC<INewsletterComponentProps> = ({ identifier }) => {
  const [email, setEmail] = useState<string>('');
  const [t] = useTranslation(['form', 'common']);

  const [subscribe] = useMutation<
    SubscribeNewsletterMutation,
    MutationSubscribeArgs
  >(SubscribeNewsletterDocument);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const fieldId = `email-newsletter-${identifier}`;
  const form = useForm();

  const doSubmit = async (e: React.FormEvent<NewsletterForm>): Promise<void> => {
    e.preventDefault();

    form.setHasFormSubmitted(true);

    if (form.isFormValid) {
      try {
        setVisible(true);
      } catch (error) {
        setVisible(false);
      }

      return;
    }
  };

  const handleConfirm = async (): Promise<void> => {
    setVisible(false);

    newsletterSubscriptionTracking(email);

    setConfirmLoading(true);
    const variables = {
      input: {
        email: email,
        firstName: '',
        lastName: '',
      },
    };

    try {
      await subscribe({
        variables,
      });

      setSuccess(true);
    } catch (error: unknown) {
      Bugsnag.notify(JSON.stringify(error));
    }

    setConfirmLoading(false);
  };

  const LinkText = (
    props: React.PropsWithChildren<LinkProps>
  ): React.ReactElement => {
    return (
      <Link {...props} href={props.href || ''} target="_blank" rel="noreferrer">
        {props.children}
      </Link>
    );
  };

  return (
    <div className={styles.formContainer}>
      <Popconfirm
        text={
          <Trans
            i18nKey={'form:newsletter.subscription-confirmation'}
            components={{
              linktext: <LinkText href="/datenschutz" />,
            }}
          />
        }
        isOpen={visible}
        onConfirm={handleConfirm}
        onCancel={(): void => {
          setVisible(false);
        }}
        okText={t('common:yes')}
        cancelText={t('common:no')}
        icon={'warning'}
      />

      <form onSubmit={doSubmit}>
        <div className={styles.newsletter}>
          {false === success && (
            <>
              <div className={styles.inputWrapper}>
                <Input
                  label={t('form:email-address')}
                  id={fieldId}
                  name={'email'}
                  autoFocus={false}
                  autoComplete={'email'}
                  onChange={(value) => {
                    setEmail(value);
                  }}
                  type={'text'}
                  isLoading={confirmLoading}
                  value={email}
                  form={form}
                  rules={[{
                    type: ValidationTypes.EMAIL,
                    message: t('form:error.email-is-invalid'),
                  }]}
                />
              </div>

              <div className={styles.buttonWrapper}>
                <Button
                  type="submit"
                  spacing={'small'}
                  loading={confirmLoading}
                >
                  {t('form:newsletter.subscribe-button-text')}
                </Button>
              </div>
            </>
          )}

          {true === success && (
            <div className={styles.successMessage}>
              {t('form:newsletter.subscription-success')}
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export const Newsletter = React.memo(NewsletterComponent, () => {
  return true;
});
