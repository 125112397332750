import { useStaticSearch } from '../../@types/codegen/page';
import { CategoryFragment } from '../../@types/codegen/graphql';

export const ssrGeneratePageUrl = (pageName: string, url?: string, categories?: CategoryFragment[], tenantKey?: string): string => {
  if ('TenantPage' === pageName || 'tenant-page' === pageName || 'TenantPageEntity' === pageName) {
    return `/vermieter/${url}`;
  }

  if ('TenantsWidget' === pageName) {
    return `/tenants${url ? `/${url}` : ''}`;
  }

  if ('ConfiguratorPage' === pageName) {
    return `/configurator/${url}`;
  }

  if ('job' === pageName || 'JobEntity' === pageName) {
    return `/jobs/${url}`;
  }

  if ('StandardPageEntity' === pageName || 'StandardPage' === pageName || 'standard-page' === pageName) {
    return `/${url}`;
  }

  if ('search-page' === pageName || 'SearchPageEntity' === pageName) {
    let linkUrl = null;
    const category = categories?.find((category) => category.uriComponent === url);

    if (category) {
      linkUrl = category.relativeUrl;
    }

    // Fallback if the categories are not loaded. The link will work but it's not correct e.g. instead /baumaschinen-mieten/bagger/minibagger it will be /baumaschinen-mieten/minibagger
    // This is not a problem because the search page will handle the url and redirect to the correct page
    if (!linkUrl) {
      linkUrl = `/baumaschinen-mieten/${url}`;

      if ('search' === url) {
        linkUrl = '/baumaschinen-mieten';
      }
    }

    // Append tenant key to the link if it's available
    if (tenantKey) {
      return `${linkUrl}?tenantKey=${tenantKey}`;
    }

    return linkUrl;
  }

  if ('BasketPage' === pageName || 'basket-page' === pageName) {
    return `/basket`;
  }

  if ('BlogOverview' === pageName || 'blog-overview' === pageName) {
    return `/blog`;
  }

  if ('BlogArticleEntity' === pageName || 'BlogArticle' === pageName || 'blog-article' === pageName) {
    return `/blog/${url}`;
  }

  if ('internal' === pageName && url) {
    return url;
  }

  if ('JobOverview' === pageName || 'job-overview' === pageName) {
    return '/jobs';
  }

  if ('job' === pageName || 'JobEntity' === pageName || 'Job' === pageName) {
    return `/jobs/${url}`;
  }

  if ('homepage' === pageName) {
    return `/homepage/de`;
  }

  console.error('Unknown url resolver information', pageName, url);
  return '/';
};

export const usePageUrl = () => {
  const { data } = useStaticSearch(() => {
    return {
      fetchPolicy: 'cache-only',
    };
  });
  const categories = data?.categories?.data ?? [];

  return {
    generatePageUrl: (pageName: string, url: string, tenantKey?: string) => ssrGeneratePageUrl(pageName, url, categories, tenantKey),
  };
};


